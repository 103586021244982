<template>
    <div>
        <div class="header pb-6 pt-5 pt-lg-8 d-flex align-items-center profile-header" style="
        min-height: 150px;
        background-size: cover;
        background-position: center top;
      ">
            <b-container fluid>
                <!-- Mask -->
                <span style="background-color: #8f384d" class="mask opacity-8"></span>
                <!-- Header container -->
                <b-container fluid class="d-flex align-items-center"> </b-container>
            </b-container>
        </div>

        <b-container fluid class="mt--6">
            <b-row>
                <b-col xl="12" class="order-xl-1">
                    <card>
                        <h1 class="mb-3">⚙️ วิชาพื้นฐาน</h1>
                        <!-- :href="item.link" target="_blank" -->
                        <b-button class="ml-3" v-for="item in be_engineer.base" :key="item.subject"
                            @click="nextLink(item.link)">
                            <img :style="'max-width: 100%;max-height: ' + windowHeight / 3 + 'px'" :src="item.img" />
                            <b-row class="text-left mt-3">
                                <b-col>
                                    <h2>{{ item.subject }}</h2>
                                </b-col>
                            </b-row>
                            <b-row class="text-left">
                                <b-col>
                                    <i>
                                        <h5 v-html="item.subject2"></h5>
                                    </i>
                                </b-col>
                            </b-row>
                            <b-row class="text-right">
                                <b-col>
                                    <h5 style="color: blue"><u>รับส่วนลด 100 บาท เมื่อกรอกโค้ด ENGENIUS</u></h5>
                                </b-col>
                            </b-row>
                        </b-button>
                        <div v-if="$store.state.department == 1">
                            <h1 class="mt-3">⚙️ วิชาเฉพาะสาขา สาขาเครื่องกล</h1>
                            <b-button class="ml-3 mt-3" v-for="item in be_engineer.me" :key="item.subject"
                                @click="nextLink(item.link)">
                                <img :style="'max-width: 100%;max-height: ' + windowHeight / 3 + 'px'"
                                    :src="item.img" />
                                <b-row class="text-left mt-3">
                                    <b-col>
                                        <h2>{{ item.subject }}</h2>
                                    </b-col>
                                </b-row>
                                <b-row class="text-left">
                                    <b-col>
                                        <i>
                                            <h5 v-html="item.subject2"></h5>
                                        </i>
                                    </b-col>
                                </b-row>
                                <b-row class="text-right">
                                    <b-col>
                                        <h5 style="color: blue"><u>รับส่วนลด 100 บาท เมื่อกรอกโค้ด ENGENIUS</u></h5>
                                    </b-col>
                                </b-row>
                            </b-button>
                        </div>
                    </card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import moment from "moment";

export default {
    components: {
    },
    watch: {},
    mounted: function () { },
    computed: {
        check_subject() {
            const arr_list = ["991", "992", "993", "994", "995", "996", "997", "998", "999"]
            if (arr_list.includes(this.$store.state.subject_index)) {
                return this.addOnSelected.includes(999)
            }
            return this.addOnSelected.includes(parseInt(this.$store.state.subject_index))
        },
        token() {
            return localStorage.token;
        },
        userPackage() {
            return this.$store.state.userPackage;
        },
        subject_index() {
            return this.$store.state.subject_index;
        },
        user_exp() {
            return moment(this.userPackage.exp).utc().format("DD/MM/YYYY HH:mm:ss");
        },
        date_remain() {
            return moment(this.userPackage.exp).diff(moment(), "days");
        },
        packageDisable() {
            return this.userPackage.exp ?
                moment(this.userPackage.exp).isAfter(moment()) :
                false;
        },
    },
    data() {
        return {
            be_engineer: {
                base: [
                    {
                        subject: "วิชา Mechanics กว.",
                        subject2: "วิชา Engineering Mechanics/Statics/Dynamics <br> สำหรับ สอบใบประกอบวิชาชีพวิศวกรรม",
                        link: "https://www.odm-engineer.com/c/mechanics-coe",
                        img: require("@/assets/be_engineer_mechanic.webp")
                    }
                ],
                me: [
                    {
                        subject: "วิชา Heat Transfer",
                        subject2: "วิชา Heat Transfer ม.ศิลปากร เนื้อหา final",
                        link: "https://www.odm-engineer.com/course/Heat-Transfer-(SU)-final-3289",
                        img: require("@/assets/be_engineer_heat_transfer.webp")
                    },
                    {
                        subject: "วิชา Machine Design",
                        subject2: "วิชา Mechanical Engineering Design <br> สำหรับ มหาลัยทั่วไป เนื้อหา midterm",
                        link: "https://www.odm-engineer.com/course/Machine-Design-%E0%B8%A1%E0%B8%AB%E0%B8%B2%E0%B8%A5%E0%B8%B1%E0%B8%A2%E0%B8%97%E0%B8%B1%E0%B9%88%E0%B8%A7%E0%B9%84%E0%B8%9B-1-3470",
                        img: require("@/assets/be_engineer_machine_design.webp")
                    },
                    {
                        subject: "วิชา Machinery",
                        subject2: "เรียนลงลึกเกี่ยวกับกลไกต่างๆภายในเครื่องจักร <br> การวิเคราะห์ความเร็ว ความเร่ง ซึ่งนำไปสู่การวิเคราะห์แรง",
                        link: "https://www.odm-engineer.com/course/Machinery-(KKU)-final-3011",
                        img: require("@/assets/be_engineer_machinery.webp")
                    }
                ]
            },

            show_sheet: false,
            visible: false,
            index_solve: 0, // default: 0
            imgs: [],
            addOnSelected: [],
            windowHeight: 0,
            index: -1,
            subject_current: "",

            number_exam: 10,
            modals: {
                notice: false
            },
            randomQ: false,
            solve: false,
            solve1: false,
            perPage: 1000,
            currentPage: 1,
            fields: ["question"],
            items: [],
            //   click: 0,

            form: {
                email: "",
                name: "",
                addition: "",
                receive: true,
                star: 3,
            },
            info: {
                city: "",
                country: "",
                ip: "",
                loc: "",
                org: "",
                postal: "",
                region: "",
                timezone: "",
                mobile: "",
                userAgent: "",
                vendor: "",
                opera: "",
                web: "",
            },
            msgtype: "danger",
            msgAlert: "",
            dismissSecs: 5,
            dismissCountDown: 0,

            bgColor: "#778899",
            successColor: "green",
            position: "buttom-left",
            position2: "top-left",
            toggleWhenAway: false,
            fixedTooltip: true,
            fabActions: [{
                name: "lightbulb",
                icon: "lightbulb",
                color: "#778899",
            },
            {
                name: "compress",
                icon: "compress",
                color: "#778899",
            },
            {
                name: "shuffle",
                icon: "shuffle",
                color: "#778899",
            },
            {
                name: "autorenew",
                icon: "autorenew",
                color: "#778899",
            },
            ],
        };
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        nextLink(link) {
            // window.location.href = link;
            var payload = {
                username: this.$store.state.username,
                param: link,
                code: "be-engineer",
            };
            this.$store.commit("POST_CLICK", payload);
            window.open(link, '_blank')
        },
        handleResize() {
            this.windowHeight = window.innerHeight - 145;
        },
        onClickSheet(sheetIndex) {
            this.$nextTick(function () {
                var arr = [];
                if (sheetIndex == 999) {
                    arr = [{
                        "namegroup": "",
                        "subject": "พระราชบัญญัติวิศวกร ข้อบังคับ ระเบียบและแนวทางการประกอบวิชาชีพ",
                        "index": 991,
                        "test": 25
                    },
                    {
                        "namegroup": "",
                        "subject": "จรรยาบรรณในการประกอบวิชาชีพ",
                        "index": 992,
                        "test": 25
                    },
                    {
                        "namegroup": "",
                        "subject": "กฏหมายเกี่ยวข้องกับวิชาชีพวิศวกร",
                        "index": 993,
                        "test": 25
                    },
                    {
                        "namegroup": "",
                        "subject": "สิ่งแวดล้อมสำหรับวิศวกร",
                        "index": 994,
                        "test": 25
                    },
                    {
                        "namegroup": "",
                        "subject": "ความปลอดภัยในงานวิศวกร",
                        "index": 995,
                        "test": 25
                    },

                    ];
                } else if (sheetIndex == 2046) {
                    arr = [{
                        "namegroup": "",
                        "subject": "ชีทสรุป Electrical System Design",
                        "index": 2046,
                        "test": 25
                    }];
                } else if (sheetIndex == 2001) {
                    arr = [{
                        "namegroup": "",
                        "subject": "ชีทสรุป Engineering Materials",
                        "index": 2001,
                        "test": 25
                    }];
                } else if (sheetIndex == 2045) {
                    arr = [{
                        "namegroup": "",
                        "subject": "ชีทสรุป Electrical Machine",
                        "index": 2045,
                        "test": 25
                    }];
                }
                this.$store.state.subject_names = [];
                var temp = [];
                for (let i = 0; i < arr.length; i++) {
                    temp.push({
                        value: arr[i].index,
                        text: arr[i].namegroup + " " + arr[i].subject,
                    });
                }
                this.$store.state.subject_names = temp;
                this.$store.state.subject_index = arr[0].index;
            });
            this.show_sheet = true;
        },
        onClick(i) {
            this.index = i;
        }
    },
};
</script>

<style></style>
